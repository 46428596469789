:root {
    --app-height: 100vh;
    --bubble-bg: white;
    touch-action: pan-x pan-y;
}

body {
    margin: 0;
    font-family: sans-serif;
    overflow: hidden;
    width: 100vw;

    height: var(--app-height);
    min-height: var(--app-height);
    max-height: var(--app-height);
}

div#root {
    height: 100%;
}

.noScrollbar {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.noScrollbar::-webkit-scrollbar {
    display: none;
}

.noSelect {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.safeBottomPadding {
    padding: 0 0 env(safe-area-inset-bottom);
}

.flexSpacer {
    flex-grow: 1;
}


/*@media (prefers-color-scheme: dark) {
    .invertWhenDark {
        filter: invert(100%);
    }

    :root {
        --bubble-bg: #333333;
    }
}*/
